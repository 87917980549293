import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  isManagerOrSuperUser,
  isSuperUser,
} from "../../utils/common_functions";
import "./style.css";
import SearchIcon from "../../assets/Search.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import _ from "lodash";
import { CircularLoader } from "../CircularLoader";
import {
  getSearchParamFromURL,
  initializeSearch,
  getRemainingTeamsCount,
} from "../../utils/TableWithActionColumnFunctions";

const Avatars = ({ users, total_users }) => {
  const firstLetter = (firstname) => firstname.charAt(0).toUpperCase();
  const list_names = [];
  const listNames = users.slice(3).map((user) => list_names + user.username);

  return (
    <Grid
      container
      spacing={1}
      style={{ paddingLeft: "10px", paddingTop: "5px" }}
    >
      {users.slice(0, 3).map((url, index) => (
        <Grid item key={url.username}>
          <Tooltip title={url.username} key={url.username}>
            {url.user_avatar !== null ? (
              <img
                key={url.user_avatar}
                style={{ color: "#3e3c49" }}
                loading="lazy"
                className={`avatar avatar-${index + 1}`}
                src={url.user_avatar}
                alt={url.username}
              />
            ) : (
              <div
                style={{
                  color: "white",
                  backgroundColor:
                    url.color_code == "string" ? "gray" : url.color_code,
                }}
                className={`avatar avatar-${index + 1}-alt`}
              >
                {firstLetter(url.username)}
              </div>
            )}
          </Tooltip>
        </Grid>
      ))}

      <Grid
        item
        style={{
          marginTop: "0.4em",
          fontFamily: "sans-serif",
          // marginLeft: "1em",
        }}
      >
        {total_users > 3 ? (
          <div>{getRemainingTeamsCount(total_users - 3, listNames)}</div>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};
const AvailabilityCell = ({ row }) => {
  const availability =
    "Active:" + " " + String(row.active_users) + "/" + String(row.total_users);
  return (
    <Grid container className="activeMembers">
      <Grid item>
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <circle cx="4" cy="4" r="4" fill="#008243" />
        </svg>
      </Grid>
      <Grid item style={{ paddingLeft: "8px", fontFamily: "sans-serif" }}>
        {" "}
        {availability}
      </Grid>
    </Grid>
  );
};

const MenuButtonIcon = ({ handleEditTeam, row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return row.row_identifier == "team" ? (
    <>
      <IconButton
        className="actionButtonMenu"
        onClick={handleOpenMenu}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        sx={{
          width: "12vw",
          padding: "0",
          height: "inherit",
          "& .MuiMenu-paper": {
            minHeight: "2.3rem",
            overflow: "hidden",
            borderRadius: "10px",
          },
          "& .MuiMenu-list": {
            padding: 0,
          },
        }}
      >
        {isManagerOrSuperUser() && (
          <MenuItem
            onClick={(event) =>
              handleEditTeam(event, row.id, "edit", row.clickup_list_id)
            }
            sx={{
              padding: "4px 0px 0px 0px",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#f3ebff",
                cursor: "pointer",
              },
            }}
          >
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) =>
                handleEditTeam(event, row.id, "edit", row.clickup_list_id)
              }
            >
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
            Edit team
          </MenuItem>
        )}
        <MenuItem
          onClick={(event) =>
            handleEditTeam(event, row.id, "view", row.clickup_list_id)
          }
          sx={{
            padding: "0px 10px 4px 0px",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "#f3ebff",
              cursor: "pointer",
            },
          }}
        >
          <IconButton
            onClick={(event) =>
              handleEditTeam(event, row.id, "view", row.clickup_list_id)
            }
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
          >
            <VisibilityOutlinedIcon fontSize="small" />
          </IconButton>
          View details
        </MenuItem>
      </Menu>
    </>
  ) : (
    <></>
  );
};

const TableWithExpandableRows = ({
  teamsData,
  isLoading,
  fetchUsersSeachList,
  fetchMoreOnBottomReached,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [redirectValue, setRedirectValue] = useState("");
  const [redirectValueAdd, setRedirectValueAdd] = useState("");
  const [shouldRedirectEditAndView, setShouldRedirectEditAndView] =
    useState(false);
  const [shouldRedirectAddTeam, setShouldRedirectAddTeam] = useState(false);
  const [hasTeamSearched, setHasTeamSearched] = useState(false);

  const [debouncedValue, setDebouncedValue] = useState("");
  const [rowData, setRowData] = useState(teamsData);

  useEffect(() => {
    initializeSearch(
      getSearchParamFromURL,
      setSearchText,
      setHasTeamSearched,
      setDebouncedValue
    );
  }, [getSearchParamFromURL]);

  useEffect(() => {
    setRowData(teamsData);

    if (searchText == "")
      setRowData(_.uniqBy([...rowData, ...teamsData], "id"));
  }, [rowData?.length, isLoading, teamsData.length]);

  useEffect(() => {
    // redirect to teams location for links
    if (shouldRedirectEditAndView) {
      window.location.href = redirectValue;
      setShouldRedirectEditAndView(false); // Reset redirect state after redirect
    }
  }, [shouldRedirectEditAndView, redirectValue]);

  useEffect(() => {
    if (shouldRedirectAddTeam) {
      window.location.href = `/teams/add-team?team=${redirectValueAdd}`;
      setShouldRedirectAddTeam(false); // Reset redirect state after redirect
    }
  }, [shouldRedirectAddTeam, redirectValueAdd]);

  useEffect(() => {
    const sid = setTimeout(() => {
      setDebouncedValue(searchText);
    }, 1000);
    return () => clearTimeout(sid);
  }, [searchText]);

  useEffect(() => {
    if (hasTeamSearched) fetchUsersSeachList(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (searchText) setSearchParams({ search: searchText });
    else setSearchParams({});
  }, [searchText, setSearchParams]);

  const handleEditTeam = (event, name, action, clickup_list_id) => {
    // set location for team links
    setRedirectValue(
      `/teams/${action}-team?team=${name}&&clickup_list_id=${clickup_list_id}`
    );
    setShouldRedirectEditAndView(true);
  };

  const handleAddTeam = () => {
    // set state for new team identification
    setRedirectValueAdd("new-team");
    setShouldRedirectAddTeam(true);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Team Name",
        enableGlobalFilter: false,
      },

      {
        header: "Users",
        accessorFn: (row) => (
          <Avatars users={row.guardian_users} total_users={row.total_users} />
        ),
      },

      {
        header: "Availability",
        accessorFn: (row) => <AvailabilityCell row={row} />,
      },
      {
        accessorKey: "active_tasks",
        header: "Active Tasks",
        size: 150,
        accessorFn: (row) => (
          <p style={{ paddingLeft: "1rem", fontStyle: "normal" }}>
            {row.active_tasks}
          </p>
        ),
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        header: "Actions",
        accessorFn: (row) => (
          <MenuButtonIcon row={row} handleEditTeam={handleEditTeam} />
        ),
        size: 70,
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
    //end
  );

  const handleScroll = (e) => {
    const clientHeight = e.target.clientHeight;
    const scrollTop = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const sum = Math.trunc(clientHeight + scrollTop - scrollHeight);
    if (sum >= -10 && isLoading === false) {
      fetchMoreOnBottomReached();
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: rowData,
    enableExpandAll: false, //hide expand all double arrow in column header
    enableExpanding: true,
    filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
    getSubRows: (row) => row.subRows, //default
    initialState: { expanded: false, density: "compact" }, //expand all rows by default, no extra padding in rows
    paginateExpandedRows: true, //When rows are expanded, do not count sub-rows as number of rows on the page towards pagination
    enableTopToolbar: false,
    enableBottomToolbar: false, //hide the bottom toolbar as well if you want
    enableGlobalFilter: false,
    enableSorting: false,
    enableFilters: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    manualPagination: true,

    renderEmptyRowsFallback: () => (
      <div className="loadingDiv">
        {isLoading ? <CircularLoader /> : "No teams found"}
      </div>
    ),

    muiTableContainerProps: {
      sx: { maxHeight: "88vh" }, //give the table a max height
      onScroll: (event) => handleScroll(event),
    },

    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        borderRadius: "5px",
        border: "1px solid #e0e0e0",
      },
    },

    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 30,
        header: "", //change header text
      },
    },

    muiTableHeadCellProps: {
      sx: {
        fontWeight: "bold",
        fontSize: "10px",
        color: "#3c3e49",
        backgroundColor: "#f5f5f5",

        "& .Mui-TableHeadCell-Content": {
          paddingTop: "10px",
        },
      },
    },

    muiTableBodyRowProps: ({ row }) => ({
      hover: false,
      sx: {
        td: {
          fontSize:
            row.depth > 1 ? `${12 - row.depth}px` : `${11 - row.depth}px`,
          fontStyle: row.depth > 0 ? "italic" : "",
          fontWeight: "501",
          color: "#3c3e49",
          backgroundColor:
            row.getIsExpanded() || row.depth > 0
              ? "var(--Base-Blue-grey-1, rgba(248, 249, 250, 1))"
              : "inherit",
        },
      },
    }),

    muiExpandButtonProps: ({ row }) => ({
      children: !row.originalSubRows == [] ? <KeyboardArrowRightIcon /> : "",
      sx: {
        transform: row.getIsExpanded() ? "rotate(-90deg)" : "",
        transition: "transform 0.2s",
      },
    }),
  });

  return (
    <Grid
      sx={{
        width: "95%",
        maxHeight: "99vh",
        backgroundColor: "transparent",
        paddingLeft: "1em",
        overflow: "hidden",
        paddingRight: "20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item className="teamsHeading" xs={8}>
          Teams
        </Grid>

        <Grid
          item
          container
          xs={4}
          sx={{
            justifyContent: "end",
          }}
        >
          <Grid
            item
            className="span-8"
            style={{
              width: "15rem",
              height: "2.25rem",
              borderRadius: "0.5rem",
              border: "1px solid var(--Base-Blue-grey-5, #C0C3CE)",
              marginTop: "2rem",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <img loading="lazy" src={SearchIcon} alt="serach" />

            <input
              type="text"
              className="div-16"
              style={{ width: "auto" }}
              placeholder={"Search team"}
              onChange={(e) => {
                setHasTeamSearched(true);
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Grid>

          {isSuperUser() && (
            <Grid item sx={{ marginTop: "2rem", marginLeft: "1rem" }}>
              <Button className="addTeamButton" onClick={handleAddTeam}>
                {" "}
                <AddIcon fontSize="small" /> Add team
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <MaterialReactTable table={table} />
    </Grid>
  );
};

export default TableWithExpandableRows;
