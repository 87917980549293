import { useEffect, useRef, useState } from "react";
import "./styles.css";
import { findUserSelectedParams } from "../../utils/FindUserSelectedParams";
import {
  clearAllSelectedFiltersForDashbooard,
  handleParentFilterUnchecked,
  generateParamString,
  findChildFilterSubElements,
  updateUserSelectedFilter,
  FilterSubOptions,
} from "../../utils/clearAllSelectedFiltersForDashboard";
import {
  capitalizeFirstLetter,
  displaySelectedFilters,
  handleChildFilterUnchecked,
  handleParentClick,
  removeCheckFromAllSuboptions,
  updateUrlWithFilters,
} from "../../utils/clearAllSelectedFilters";
import {
  findFilterOptionsForDashboard,
  findSlaData,
  getSubFilterClassName,
  updateFirstResponseParam,
  findLegendsToBeDisplayed,
  filterDataByDuration,
  formatStatusData,
  formatSquadData,
  calculateMaxValue,
  adjustDataForVisualization,
  formatClosedData,
  isDataEmpty,
  isClosedDataEmpty,
  calculateBarLabel,
  CustomItemTooltipContentStatus,
  CustomItemTooltipContentTeam,
  getLevelRows,
  getSquadRows,
  getStatusRows,
} from "../../utils/DashboardFunctions";
import { defaultUserSelectedFilter } from "./data";
import { errorNotifyList } from "../../components/ToastComponent";
import { ToastContainer } from "react-toastify";
import DashboardBarChart from "../../components/DashboardBarChart";
import {
  keys,
  colors,
  valueFormatter,
  legends,
  keysStatus,
  colorsStatus,
  valueFormatterStatus,
  keysSquad,
  colorsSquad,
} from "../../components/DashboardBarChart/series";
import Skeleton from "@mui/material/Skeleton";
import { removeDateParamsFromUrl } from "../../components/DashboardCards/CardsFunctions";
import {
  getClosedBarChartData,
  getClosedTickets,
  getDashboardMetricData,
  getFiltersData,
  getFirstResponseSLA,
  getOpenTickets,
  getSquadBarChartData,
  getStatusBarChartData,
  getDashboardMetricExportData,
} from "../../services/apiServices";
import FirstResponseSLA from "../../components/FirstResponseSLAComponent";
import ClosedTickets from "../../components/ClosedCardsComponent";
import OpenTickets from "../../components/OpenTicketsComponent";
import Header from "../../components/Header/index";
import {
  levelHeadCells,
  levelTableKeys,
  statusHeadCells,
  statusTableKeys,
  squadHeadCells,
  squadTableKeys,
} from "../../utils/DashboardModalConstants";
import { REACT_APP_API_PATH as baseURL, CLICKUP_URL } from "../../config";
import { useURLInfoDashboard } from "../../utils/useURLInfo";

export const DashboardComponent = () => {
  const [allCardsData, setAllCardsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [metricIsLoading, setMetricIsLoading] = useState(false);

  const [closedTicketsCards, setClosedTicketsCards] = useState([]);
  const [firstResponseCard, setFirstResponseCard] = useState({});
  const [totalClosedTicketsCount, setTotalClosedTicketsCount] = useState(0);
  const [totalFirstResponseCount, setTotalFirstResponseCount] = useState(0);
  const [isClosedTicketsLoading, setIsClosedTicketsLoading] = useState(true);
  const [isFirstResponseLoading, setIsFirstResponseLoading] = useState(true);

  // For Bar Chart
  const [statusBarData, setStatusBarData] = useState([]);
  const [squadBarData, setSquadBarData] = useState([]);
  const [closedBarData, setClosedBarData] = useState([]);
  const [url, setUrl] = useState(`${baseURL}v1/dashboard_metric/?limit=100`);
  const [statusRows, setStatusRows] = useState([]);
  const [levelRows, setLevelRows] = useState([]);
  const [squadRows, setSquadRows] = useState([]);
  const [countTickets, setCountTickets] = useState();

  /* For filters */
  const [allFilters, setAllFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState([]);

  const [userSelectedFilter, setUserSelectedFilter] = useState(
    defaultUserSelectedFilter
  );

  //For checking if user filters are set or not
  const [isUserFilterSet, setIsUserFilterSet] = useState(false);

  const [parameterString, setParameterString] = useState("");

  const [openTicketsError, setOpenTicketsError] = useState(false);
  const [closedTicketsError, setClosedTicketsError] = useState(null);
  const [firstResponseError, setFirstResponseError] = useState(null);

  const urlUserSelectedParams = useURLInfoDashboard();

  const dashboardInit = useRef(true);

  const checkClearAllVisibility = () => {
    let flag = false;

    Object.keys(userSelectedFilter).forEach((item) => {
      if (
        userSelectedFilter[item].length > 0 &&
        item !== "dashboardDate" &&
        item !== "closed tickets" &&
        item !== "open tickets"
      )
        flag = true;
      else if (item === "closed tickets") {
        flag = true;
      } else if (item === "open tickets") {
        flag = true;
      }
    });

    if (flag === true) return "visible";
    else return "hidden";
  };

  const getParamStringForClosedTickets = (parameterString) => {
    return `${parameterString.replace("&", "?")}`;
  };

  const fetchClosedTicketsData = async () => {
    setIsClosedTicketsLoading(true);
    try {
      const paramStringForClosedTickets =
        getParamStringForClosedTickets(parameterString);

      const response = await getClosedTickets(
        paramStringForClosedTickets,
        baseURL
      );

      setClosedTicketsCards(findSlaData(response.data));
      setTotalClosedTicketsCount(response.total_count);
      setClosedTicketsError(null);
    } catch (error) {
      errorNotifyList([`Closed SLA tickets HTTP error. ${error}`]);
      setClosedTicketsError(error);
    } finally {
      setIsClosedTicketsLoading(false);
    }
  };

  const fetchFiltersData = async () => {
    const data = await getFiltersData(baseURL);
    if (data && typeof data === "object") {
      delete data["sla_status"];
      delete data["status"];
    }

    setAllFilters(data);
    setFilterOptions(
      findFilterOptionsForDashboard(
        data,
        findUserSelectedParams(data),
        urlUserSelectedParams
      )
    );

    setUserSelectedFilter({
      ...userSelectedFilter,
      ...findUserSelectedParams(data),
      ...urlUserSelectedParams,
    });

    setIsUserFilterSet(true);
  };

  const fetchDataForFirstResponse = async () => {
    setIsFirstResponseLoading(true);

    try {
      const paramStringForFirstResponse =
        updateFirstResponseParam(parameterString);

      const response = await getFirstResponseSLA(
        paramStringForFirstResponse,
        baseURL
      );

      setFirstResponseCard({
        name: "First response SLA",
        count: response.achieved_count,
        percent: response.percent,
      });
      setTotalFirstResponseCount(response.total_count);
      setFirstResponseError(null);
    } catch (error) {
      errorNotifyList([`First response SLA tickets HTTP error. ${error}`]);
      setFirstResponseError(error);
    } finally {
      setIsFirstResponseLoading(false);
    }
  };

  const getOpenTicketsParamString = (parameterString) => {
    return `${removeDateParamsFromUrl(
      parameterString.replace("&", "?"),
      "completed_date_after",
      "completed_date_before"
    )}`;
  };

  const fetchDataForCards = async () => {
    setIsLoading(true);
    try {
      const paramStringForOpenTickets =
        getOpenTicketsParamString(parameterString);

      const response = await getOpenTickets(paramStringForOpenTickets, baseURL);

      if (response.data.length === 0)
        throw new Error("No data found for open SLA tickets");

      setAllCardsData(response.data);
      setTotalCount(response.total_count);
      setOpenTicketsError(null);
    } catch (error) {
      errorNotifyList([`Open SLA tickets HTTP error. ${error}`]);
      setOpenTicketsError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showFiltersDropdown = () => {
    const elem = document.getElementsByClassName("filters_dropdown")[0];
    elem.classList.toggle("show");
  };

  const handleHideAllSuboptions = (e) => {
    setFilterOptions(
      filterOptions.map((filterOption) => {
        return {
          ...filterOption,
          isHighlighted: false,
          showsuboptions: false,
        };
      })
    );
  };

  const handleChildClick = (
    e,
    parentFilterType,
    childFilterId,
    parentFilterIndex,
    childFilterIndex,
    sub,
    childFilterType
  ) => {
    e.stopPropagation();

    updateUserSelectedFilter({
      e,
      parentFilter: {
        parentFilterType,
        childFilterId,
        childFilterType,
      },
      indices: {
        parentFilterIndex,
        childFilterIndex,
      },
      sub,
      callbacks: {
        handleChildFilterUnchecked,
        setFilterOptions,
        setUserSelectedFilter,
      },
      state: {
        userSelectedFilter,
        filterOptions,
      },
      setParameterString,
      generateParamString,
    });
  };

  const handleOnDateHover = (e, subType) => {
    const dateSubTypeIndex = 5;
    setFilterOptions(
      filterOptions.map((filterOption) => {
        if (filterOption.type === "dashboardDate") {
          let tempSubOptions = [...filterOption.suboptions];
          tempSubOptions[dateSubTypeIndex].showsuboptions = true;
          return {
            ...filterOption,
            suboptions: tempSubOptions,
          };
        } else return filterOption;
      })
    );
  };

  const filterFunction = (e) => {
    let input, filter, a;
    input = e.target;

    filter = input.value.toUpperCase();

    let div = document.getElementsByClassName(
      "filter_dropdown_child_options"
    )[0];

    a = div.getElementsByClassName("sub_filter_checkbox");

    for (let elem of a) {
      let txtValue = elem.textContent || elem.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        elem.style.display = "";
      } else {
        elem.style.display = "none";
      }
    }
  };

  const getLoader = () => {
    return <Skeleton variant="rounded" width={"100%"} height={"20vh"} />;
  };

  // For Bar Chart

  const paramString = generateParamString(userSelectedFilter, null, true);

  const fetchStatusBarChartData = async (paramString) => {
    setIsLoading(true);
    const response = await getStatusBarChartData(paramString, baseURL);

    setStatusBarData(response);
    setIsLoading(false);
  };

  const filteredStatusBarData = filterDataByDuration(statusBarData);

  const maxValueStatus = calculateMaxValue(filteredStatusBarData);
  const adjustedStatusBarData = adjustDataForVisualization(
    filteredStatusBarData,
    maxValueStatus
  );

  const fetchSquadBarChartData = async (paramString) => {
    setIsLoading(true);

    const response = await getSquadBarChartData(paramString, baseURL);
    setSquadBarData(response);
    setIsLoading(false);
  };

  const filteredSquadBarData = filterDataByDuration(squadBarData);

  const maxValueSquad = calculateMaxValue(filteredSquadBarData);

  const adjustedSquadBarData = adjustDataForVisualization(
    filteredSquadBarData,
    maxValueSquad
  );
  const fetchClosedBarChartData = async () => {
    setIsLoading(true);
    const response = await getClosedBarChartData(parameterString, baseURL);

    setClosedBarData(response);
    setIsLoading(false);
  };

  const prevFetchUrl = useRef(null);

  const fetchDashboardMetricData = async (customUrl) => {
    const fetchUrl = customUrl || url;

    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;

    setMetricIsLoading(true);

    const response = await getDashboardMetricData(fetchUrl);
    const ticketsCount = response.count;

    if (customUrl) {
      setStatusRows(getStatusRows(response.results));
      setLevelRows(getLevelRows(response.results));
      setSquadRows(getSquadRows(response.results));
    } else {
      setStatusRows([...statusRows, ...getStatusRows(response.results)]);
      setLevelRows([...levelRows, ...getLevelRows(response.results)]);
      setSquadRows([...squadRows, ...getSquadRows(response.results)]);
    }

    setCountTickets(ticketsCount);

    setUrl(response.next);

    setMetricIsLoading(false);
  };

  const handleExport = async (params) => {
    setExportIsLoading(true);
    const response = await getDashboardMetricExportData(params, baseURL);

    const csvText = await response.text();
    const trimmedCsvText = csvText.trim();

    const rows = trimmedCsvText.split("\n");

    const modifiedRows = rows.map((row, index) => {
      if (index === 0) {
        return row;
      }
      const columns = row.split(",");
      const existingId = columns[0];
      const link = `${CLICKUP_URL}${existingId}`;
      columns[0] = link;
      return columns.join(",");
    });

    const modifiedCsvText = modifiedRows.join("\n");

    const blob = new Blob([modifiedCsvText], { type: "text/csv" });

    const exportsResponseUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = exportsResponseUrl;
    const currentTime = new Date().toISOString().slice(0, 19).replace(/T/, "-");
    const filename = `Guardian-Dashboard-Metric-Export-${currentTime}.csv`;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    setExportIsLoading(false);
  };

  const handleMouseOver = (e, index, filter) => {
    if (filter.type !== "closed tickets" || filter.type !== "open tickets") {
      handleParentClick(
        e,
        index,
        filter,
        filterOptions,
        setFilterOptions,
        userSelectedFilter,
        setUserSelectedFilter
      );
    } else {
      handleHideAllSuboptions();
    }
  };

  useEffect(() => {
    if (dashboardInit.current === true) {
      fetchFiltersData();
      dashboardInit.current = false;
    }
  }, []);

  useEffect(() => {
    setParameterString(generateParamString(userSelectedFilter, null, false));
    updateUrlWithFilters(userSelectedFilter);
  }, [userSelectedFilter]);

  useEffect(() => {
    if (isUserFilterSet) {
      fetchDataForCards();
      fetchClosedTicketsData();
      fetchDataForFirstResponse();
      fetchStatusBarChartData(paramString);
      fetchSquadBarChartData(paramString);
      fetchClosedBarChartData();
    }
  }, [parameterString]);

  useEffect(() => {
    const elem = document.getElementsByClassName("filters_dropdown")[0];
    const handleHideAllFilters = (e) => {
      let fixedList = [
        "div-20",
        "img-10",
        "span-10",
        "parent_filter_option",
        "filter_dropdown_child_options",
        "searchInput",
        "firstLetter",
        "assignee_sub_filter",
        "sub_filter_checkbox",
        "single_selection_sub_filter",
        "assignee-value",
      ];
      if (!fixedList.includes(Array.from(e.target.classList)[0]))
        elem.classList.remove("show");
    };

    document.addEventListener("click", handleHideAllFilters);

    return () => {
      document.removeEventListener("click", handleHideAllFilters);
    };
  }, []);

  return (
    <div className="dashboardComponent">
      <div className="header">
        <Header
          heading="Ticket Metrics Dashboard"
          showFiltersDropdown={() => showFiltersDropdown()}
          filterOptions={filterOptions}
          onMouseOver={handleMouseOver}
          handleParentFilterUnchecked={handleParentFilterUnchecked}
          parentFilterOptions={capitalizeFirstLetter}
          FilterSubOptions={FilterSubOptions}
          getSubFilterClassName={getSubFilterClassName}
          onKeyUp={filterFunction}
          handleOnDateHover={handleOnDateHover}
          handleChildClick={handleChildClick}
          setFilterOptions={setFilterOptions}
          userSelectedFilter={userSelectedFilter}
          setUserSelectedFilter={setUserSelectedFilter}
          findChildFilterSubElements={findChildFilterSubElements}
          displaySelectedFilters={displaySelectedFilters(
            userSelectedFilter,
            capitalizeFirstLetter,
            allFilters,
            setUserSelectedFilter,
            filterOptions,
            setFilterOptions
          )}
          checkClearAllVisibility={`${checkClearAllVisibility()}`}
          clearAllSelectedFilters={() =>
            clearAllSelectedFiltersForDashbooard(
              setUserSelectedFilter,
              setFilterOptions,
              filterOptions,
              removeCheckFromAllSuboptions
            )
          }
          searchFieldStyle={{
            display: "none",
          }}
          style={{
            display: "none",
          }}
          exportButtonDisplay="none"
        />
      </div>
      <div className="content">
        <div className="dashboard_cards_row_container">
          <OpenTickets
            isLoading={isLoading}
            getLoader={getLoader}
            openTicketsError={openTicketsError}
            allCardsData={allCardsData}
            totalCount={totalCount}
            parameterString={parameterString}
          />

          <div className="dashboard_cards_container dashboard_closed_first_response_sectiion">
            <FirstResponseSLA
              isFirstResponseLoading={isFirstResponseLoading}
              getLoader={getLoader}
              firstResponseError={firstResponseError}
              firstResponseCard={firstResponseCard}
              totalFirstResponseCount={totalFirstResponseCount}
              parameterString={parameterString}
            />
            <ClosedTickets
              isClosedTicketsLoading={isClosedTicketsLoading}
              getLoader={getLoader}
              closedTicketsError={closedTicketsError}
              closedTicketsCards={closedTicketsCards}
              totalClosedTicketsCount={totalClosedTicketsCount}
              parameterString={parameterString}
            />
          </div>
        </div>
        <div className="barChartDisplay">
          <DashboardBarChart
            sx={{ width: "60%", borderRadius: "8px" }}
            title="Average time spent in each status"
            metricIsLoading={metricIsLoading}
            countTickets={countTickets}
            parameters={paramString}
            fetchData={fetchDashboardMetricData}
            tableData={statusRows}
            tableHeadCells={statusHeadCells}
            tableKeys={statusTableKeys}
            isLoading={isLoading}
            isDataEmpty={isDataEmpty(statusBarData, "duration")}
            data={formatStatusData(adjustedStatusBarData)}
            keys={keysStatus}
            colors={colorsStatus}
            label={(item) => calculateBarLabel(item, maxValueStatus)}
            valueFormat={valueFormatterStatus}
            tooltip={(input) => CustomItemTooltipContentStatus(input)}
            noDataTitle="No data to display for Average time spent in each status"
            handleExport={() => handleExport(paramString)}
            exportIsLoading={exportIsLoading}
          />
          <DashboardBarChart
            sx={{ width: "38%", borderRadius: "8px", marginLeft: "2rem" }}
            title="Average time spent in each level"
            metricIsLoading={metricIsLoading}
            countTickets={countTickets}
            parameters={paramString}
            fetchData={fetchDashboardMetricData}
            tableData={levelRows}
            tableHeadCells={levelHeadCells}
            tableKeys={levelTableKeys}
            isLoading={isLoading}
            isDataEmpty={isDataEmpty(squadBarData, "duration")}
            data={formatSquadData(adjustedSquadBarData)}
            keys={keysSquad}
            colors={colorsSquad}
            label={(item) => calculateBarLabel(item, maxValueSquad)}
            valueFormat={valueFormatterStatus}
            tooltip={(input) => CustomItemTooltipContentStatus(input)}
            noDataTitle="No data to display for Average time spent in each level"
            handleExport={() => handleExport(paramString)}
            exportIsLoading={exportIsLoading}
          />
        </div>

        <DashboardBarChart
          title="Tickets closed by squad"
          metricIsLoading={metricIsLoading}
          countTickets={countTickets}
          parameters={parameterString}
          fetchData={fetchDashboardMetricData}
          tableData={squadRows}
          tableHeadCells={squadHeadCells}
          tableKeys={squadTableKeys}
          isLoading={isLoading}
          isDataEmpty={isClosedDataEmpty(closedBarData)}
          data={formatClosedData(closedBarData)}
          keys={keys}
          colors={colors}
          label={(item) => {
            if (item.value === 0) {
              return null;
            }

            return `${item.value}%`;
          }}
          valueFormat={valueFormatter}
          labelSkipHeight={8}
          legends={findLegendsToBeDisplayed(
            legends,
            formatClosedData(closedBarData)
          )}
          tooltip={(input) =>
            CustomItemTooltipContentTeam(input, closedBarData)
          }
          noDataTitle="No data to display for Tickets closed by squad"
          handleExport={() => handleExport(parameterString)}
          exportIsLoading={exportIsLoading}
        />
      </div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <>
      <DashboardComponent />
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default Dashboard;
